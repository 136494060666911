<template>
  <div>
    <ManageAcademicConferences3 />
  </div>
</template>
<script>
import ManageAcademicConferences3 from "@/components/ManageAcademicJournal/ManageAcademicConferences3";
export default {
  components: {
    ManageAcademicConferences3,
  },
  created() {},
};
</script>
